import React from 'react';
import { Button, Row, Form, Input, Col, Select, Typography } from 'antd';
import { BusinessTypes, RequestTypes } from '../../data/common-data';
import { colors } from '../../style-vars';
import chevron from '../../images/svg/chevron_left.svg';

const { Option } = Select;
const { Title } = Typography;

const ContactUsForm = () => {
  return (
    <div className="contact-us-form">
      <Title level={2} className="text-center">
        Send us a message
      </Title>
      <Form layout="vertical">
        <Row gutter={[32, 0]}>
          <Col lg={12} sm={24}>
            {/* Business Name */}
            <Form.Item
              label="Business name"
              name="company"
              rules={[
                {
                  required: true,
                  message: 'Please enter your business name',
                  min: 3,
                },
              ]}
            >
              <Input size="large" placeholder="e.g. MyBusiness LLC " />
            </Form.Item>
          </Col>
          <Col lg={12} sm={24}>
            <Form.Item
              name="phone"
              label="Phone"
              rules={[
                {
                  required: true,
                  message: 'Please enter your phone',
                  min: 7,
                },
              ]}
            >
              <Input size="large" placeholder="e.g. (555) 555-1243 " />
            </Form.Item>
          </Col>
          <Col lg={12} sm={24}>
            <Form.Item
              style={{ overflow: 'auto' }}
              name="industry"
              label="Industry of your business"
              rules={[
                {
                  required: true,
                  message: 'Please enter the type of the project',
                },
              ]}
            >
              <Select
                placeholder="Select your industry"
                style={{ width: '100%', color: 'black !important' }}
              >
                {BusinessTypes.map(({ title, value }) => (
                  <Option key={value} value={value}>
                    {title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={12} sm={24}>
            <Form.Item
              style={{ overflow: 'auto' }}
              name="requestType"
              label="Your kind of request"
              rules={[
                {
                  required: true,
                  message: 'Please enter the type of request',
                },
              ]}
            >
              <Select
                placeholder="Please Select"
                style={{ width: '100%', color: 'black !important' }}
              >
                {RequestTypes.map(({ title, value }) => (
                  <Option key={value} value={value}>
                    {title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={24}>
            <Form.Item
              label="How can we help you?"
              name="textarea"
              rules={[
                {
                  required: true,
                  message: 'Please describe how we can help',
                  min: 3,
                },
              ]}
            >
              <Input.TextArea rows={4} placeholder="e.g. MyBusiness LLC " />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center" align="middle">
          <Col lg={24}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="cta-primary"
                style={{ marginTop: 20 }}
                size="small"
              >
                Submit
                <img
                  src={chevron}
                  alt="chevron icon"
                  style={{ margin: '5px 5px 2px 10px' }}
                />
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <style jsx>{`
        .contact-us {
          position: relative;
        }

        .contact-us-form {
          padding: 48px;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.07),
            20px 10px 40px rgba(47, 80, 167, 0.1);
          margin: -250px 20% 50px 20%;
          border-radius: 8px;
          background: ${colors.white};
        }

        .contact-us-form :global(.cta-primary) {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
        }
      `}</style>
    </div>
  );
};

export default ContactUsForm;
