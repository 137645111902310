import React from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";
import { Button } from "antd";

import chevron from "../../images/svg/chevron_secondary.svg";

const CtaSecondaryAlt = (props) => {
  const {
    ctaTitle,
    target,
    className,
    reverseIcon,
    openInNewTab,
    clickHandler,
    show,
  } = props;

  return clickHandler ? (
    <Button
      className={`cta-secondary ${className}`}
      type="secondary"
      onClick={() => clickHandler(show)}
    >
      {reverseIcon && (
        <img
          src={chevron}
          alt="chevron icon"
          style={{
            margin: "5px 24px 2px 10px",
            transform: "rotate(180deg)",
          }}
        />
      )}
      {ctaTitle}
      {!reverseIcon && (
        <img
          src={chevron}
          alt="chevron icon"
          style={{ margin: "2px 5px 0px" }}
        />
      )}
    </Button>
  ) : (
    <a className={`cta-secondary ${className}`} type="secondary" href={target}>
      {reverseIcon && (
        <img
          src={chevron}
          alt="chevron icon"
          style={{
            margin: "5px 24px 2px 10px",
            transform: "rotate(180deg)",
          }}
        />
      )}
      {ctaTitle}
      {!reverseIcon && (
        <img
          src={chevron}
          alt="chevron icon"
          style={{ margin: "2px 5px 0px" }}
        />
      )}
    </a>
  );
};

CtaSecondaryAlt.propTypes = {
  className: PropTypes.string,
  ctaTitle: PropTypes.string,
  target: PropTypes.string,
  reverseIcon: PropTypes.bool,
  openInNewTab: PropTypes.bool,
  clickHandler: PropTypes.func,
  show: PropTypes.string,
};
CtaSecondaryAlt.defaultProps = {
  className: "",
  ctaTitle: "Primary CTA",
  target: "/",
  reverseIcon: false,
  openInNewTab: false,
  clickHandler: false,
  show: false,
};

export default CtaSecondaryAlt;
