// eslint-disable-next-line import/prefer-default-export
export const contactUsHero = {
  title: 'Contact us.',
  subtext:
    'Our team is here to help you 24 hours a day, each and every day. Support is unlimited.',
};
export const contactUsSubverticals = {
  subverticalInfo: [
    {
      iconImg: 'sales.png',
      subverticalName: 'Sales.',
      subverticalContent:
        'If you have any questions about getting started, we’re here to help on our email or 877-814-4102.',
      links: [
        {
          linkTitle: 'Contact Us',
          linkTarget: '/page-2',
        },
      ],
    },
    {
      iconImg: 'help.png',
      subverticalName: 'Support.',
      subverticalContent:
        'Have an issue with your account? Want expert advice on creating a marketing strategy? We’re here to help.',
      links: [
        {
          linkTitle: 'Contact Us',
          linkTarget: '/page-2',
        },
      ],
    },
    {
      iconImg: 'marketng.png',
      subverticalName: 'Media relations.',
      subverticalContent:
        'Are you media and looking to do a story about SpotOn?\n',
      links: [
        {
          linkTitle: 'Contact Us',
          linkTarget: '/page-2',
        },
      ],
    },
  ],
};
