import React from "react";
import { viewports, colors } from "../../style-vars";

const { mdOnly, smOrSmaller } = viewports;
const { white, black } = colors;

const SubverticalsStyles = () => (
  <style jsx global>{`
    .subverticals {
      position: relative;
      margin-top: 100px;
      margin-bottom: 80px;
      padding: 0 160px;
    }
    @media ${mdOnly} {
      .subverticals {
        padding: 0 100px !important;
      }
    }
    @media ${smOrSmaller} {
      .subverticals {
        padding: 0 32px !important;
      }
    }
    .subverticals h1 {
      font-weight: 800 !important;
      margin-bottom: 60px !important;
    }
    .subverticals h2 {
      font-weight: 800 !important;
      margin-bottom: 16px !important;
    }
    .subverticals__card {
      background-color: ${white};
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.07),
        20px 10px 40px rgba(47, 80, 167, 0.1);
      border-radius: 8px;
      padding: 24px;
      display: flex;
      flex-direction: column;
      flex: 1 !important;
      height: 100%;
    }
    .subverticals__card:hover {
      animation: shadow-drop-2-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94)
        both;
    }
    .subverticals__card h3.ant-typography {
      font-size: 24px !important;
      color: ${black} !important;
      margin-top: 18px;
    }
    .subverticals__card p {
      color: #6e7682;
      line-height: 1.7;
    }
    .subverticals__card .cta-secondary {
      padding-left: 0 !important;
      display: flex;
      margin-bottom: 8px;
      color: ${black} !important;
    }
    .fake-center {
      display: table;
      margin: 0 auto 64px auto;
    }
    /**
 * ----------------------------------------
 * animation shadow-drop-2-center
 * ----------------------------------------
 */
    @keyframes shadow-drop-2-center {
      0% {
        transform: translateZ(0);
        box-shadow: 0 0 0 0 rgba(23, 105, 255, 0.8);
      }
      100% {
        transform: translateZ(50px);
        box-shadow: 0 0 20px 0px rgba(23, 105, 255, 0.8);
      }
    }
  `}</style>
);

export default SubverticalsStyles;
