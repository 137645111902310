import React from "react";
import { Row, Col } from "antd";
import Layout from "../components/Layout/layout";
import SEO from "../components/seo";
import ContactUsHero from "../components/Hero/contact-us-hero";
import { contactUsHero } from "../data/contact-us-data";
import CtaSecondary from "../components/Buttons/secondary-cta";
import ContactUsForm from "../components/ConversionPath/ContactForm";

import heroBg from "../images/hero-images/contact-us-hero.png";
import knowledge from "../images/subvertl-images/knowledge.png";
import team from "../images/subvertl-images/team.png";

export default function ContactForm() {
  return (
    <Layout>
      <SEO title="SpotOn - Contact Us" image={`https://spoton.com/${heroBg}`} />
      <ContactUsHero sectionData={contactUsHero} heroBg={heroBg} />
      <ContactUsForm />
      <Row justify="center">
        <Col lg={12}>
          <div className="small-grid">
            <img className="small-grid-icon" src={knowledge} alt="" />
            <div>
              <h3 className="font-bold">Knowledge Base</h3>
              <CtaSecondary ctaTitle="Support documentation" target="/" />
            </div>
          </div>
        </Col>
        <Col lg={12}>
          <div className="small-grid">
            <img className="small-grid-icon" src={team} alt="" />
            <div>
              <h3 className="font-bold">Help Center</h3>
              <CtaSecondary ctaTitle="SpotOn help center" target="/" />
            </div>
          </div>
        </Col>
      </Row>
    </Layout>
  );
}
