import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Typography } from 'antd';
import Subverticals from '../SubVerticals/Subverticals';
import HeroStyles from './HeroStyles';

const { Title } = Typography;

const ContactUsHero = (props) => {
  const { sectionData, heroBg, subverticals } = props;
  const { title, subtext } = sectionData;
  return (
    <section
      className="contact-us-hero"
      style={{ backgroundImage: `url(${heroBg})` }}
    >
      <Row align="middle">
        <Col lg={24} xs={24} className="hero__content">
          <Title className="hero-b__title text-center">{title}</Title>
          <p className="hero-b__para text-center">{subtext}</p>
        </Col>
      </Row>
      {subverticals && <Subverticals sectionData={subverticals} />}

      <HeroStyles />
    </section>
  );
};

ContactUsHero.propTypes = {
  sectionData: PropTypes.objectOf(PropTypes.any),
  subverticals: PropTypes.objectOf(PropTypes.any),
  heroBg: PropTypes.string,
};
ContactUsHero.defaultProps = {
  sectionData: '',
  heroBg: '',
  subverticals: '',
};

export default ContactUsHero;
