import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Typography } from "antd";
import { Link } from "gatsby";
import Image from "../ImageQuerys/SubvertImages";
import CtaSecondary from "../Buttons/secondary-cta";
import CtaSecondaryAlt from "../Buttons/cta-secondary-alt";
import SubverticalsStyles from "./SubverticalsStyles";
import Heading from "../../components-v2/Base/HeadingBuilder";

const { Title } = Typography;

const Subverticals = (props) => {
  const { sectionData, style, clickHandler } = props;
  const { title, ctaInfoSecondary, subverticalInfo, noMore } = sectionData;
  return (
    <section className="subverticals" style={style}>
      {title && (
        <Heading
          level={2}
          injectionType={3}
          symbol="."
          withBlueSymbol
          className="text-center mb-20"
        >
          {title}
        </Heading>
      )}
      {ctaInfoSecondary && (
        <CtaSecondary
          className="fake-center"
          ctaTitle={ctaInfoSecondary.ctaTitle}
          target={ctaInfoSecondary.ctaTarget}
        />
      )}
      <Row gutter={[32, 32]}>
        {!clickHandler &&
          subverticalInfo.map((vert, idx) => (
            <Col
              key={`subvertical__${idx.toString()}`}
              lg={subverticalInfo.length % 2 !== 0 ? 8 : 12}
              md={24}
              sm={24}
              xs={24}
            >
              <Link to={vert.target || ""}>
                <div className="subverticals__card">
                  <Image imageName={vert.iconImg} style={{ width: "50px" }} />
                  <Heading level={3} injectionType={4} className="mb-0 my-4">
                    {vert.subverticalName}
                  </Heading>
                  <p>{vert.subverticalContent}</p>
                  {vert?.links && (
                    <Row>
                      {vert.links.map((link, index) => (
                        <Col
                          lg={12}
                          key={`subverticals__list__${index.toString()}`}
                        >
                          <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                            <CtaSecondary
                              ctaTitle={link.linkTitle}
                              target={link.linkTarget}
                            />
                          </div>
                        </Col>
                      ))}
                    </Row>
                  )}
                </div>
              </Link>
            </Col>
          ))}
        {clickHandler &&
          subverticalInfo.map((vert, idx) => (
            <Col
              key={`subvertical__${idx.toString()}`}
              lg={subverticalInfo.length === 3 ? 8 : 12}
              md={24}
              sm={24}
              xs={24}
            >
              <div
                className="subverticals__card"
                onClick={() => clickHandler(vert.links[0].show)}
              >
                <Image imageName={vert.iconImg} style={{ width: "50px" }} />
                <Heading level={2} injectionType={3}>
                  {vert.subverticalName}
                </Heading>
                <p>{vert.subverticalContent}</p>
                {vert?.links && (
                  <Row>
                    {vert.links.map((link, index) => (
                      <Col
                        lg={12}
                        key={`subverticals__list__${index.toString()}`}
                      >
                        <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                          <CtaSecondaryAlt
                            ctaTitle={link.linkTitle}
                            clickHandler={clickHandler}
                            show={link.show}
                          />
                        </div>
                      </Col>
                    ))}
                  </Row>
                )}
              </div>
            </Col>
          ))}
        {!noMore && (
          <Row justify="center" align="middle" style={{ width: "100%" }}>
            <Title level={3}>... and many more</Title>
          </Row>
        )}
      </Row>
      <SubverticalsStyles />
    </section>
  );
};

Subverticals.propTypes = {
  sectionData: PropTypes.objectOf(PropTypes.any),
  style: PropTypes.objectOf(PropTypes.any),
  clickHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};
Subverticals.defaultProps = {
  sectionData: "",
  style: {},
  clickHandler: false,
};

export default Subverticals;
