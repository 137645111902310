export const BusinessTypes = [
  {
    value: "restaurants",
    title: "Restaurants & Hospitality",
  },
  {
    value: "retail",
    title: "Retail & E-commerce",
  },
  {
    value: "auto",
    title: "Automotive",
  },
  {
    value: "health-and-beauty",
    title: "Health & Beauty",
  },
  {
    value: "professional-services",
    title: "Professional Services",
  },
  {
    value: "other",
    title: "Other",
  },
];

export const NewBusinessTypes = [
  {
    value: "Hospitality",
    title: "Hospitality",
  },
  {
    value: "Retail & Services",
    title: "Retail & Services",
  },
  {
    value: "Venues",
    title: "Venues",
  },
  {
    value: "Other",
    title: "Other",
  },
];

export const EnterpriseBusinessTypes = [
  {
    value: "amusements-and-attractions",
    title: "Amusements & Attractions",
  },
  {
    value: "business-dining",
    title: "Business Dining",
  },
  {
    value: "education",
    title: "Education",
  },
  {
    value: "restaurants",
    title: "Restaurants",
  },
  {
    value: "sports-and-entertainment",
    title: "Sports & Entertainment",
  },
  {
    value: "other",
    title: "Other",
  },
];

export const hospitalityTypes = [
  "Multi-unit restaurant chain",
  "Sports & entertainment",
  "Education/campus",
  "Professional facility",
  "Amusement park",
  "Food service company",
  "Other",
];

export const AppetizeIndustryTypes = [
  "Sports & Entertainment",
  "Business",
  "Education",
  "Restaurant",
  "Amusement",
  "Lodging",
  "Retail",
];

export const Position = [
  {
    value: "business-owner",
    title: "Business Owner",
  },
  {
    value: "manager",
    title: "Manager",
  },
  {
    value: "operations-leader",
    title: "Operations Leader",
  },
  {
    value: "it-leader",
    title: "IT Leader",
  },
  {
    value: "consultant",
    title: "Consultant",
  },
  {
    value: "other",
    title: "Other",
  },
];

export const RequestTypes = [
  {
    value: "media",
    title: "Media relations",
  },
  {
    value: "support",
    title: "Support",
  },
  {
    value: "sales",
    title: "Sales",
  },
];

export const MerchantReferrals = [
  {
    value: "SpotOn Restaurant POS",
    title: "SpotOn Restaurant POS",
  },
  {
    value: "Restaurant online ordering",
    title: "Restaurant online ordering",
  },
  {
    value: "Restaurant QR order & pay",
    title: "Restaurant QR order & pay",
  },
  {
    value: "Restaurant labor management",
    title: "Restaurant labor management",
  },
  {
    name: "Reservations & waitlisting",
    value: "Reservations & waitlisting",
  },
  {
    name: "SpotOn Retail POS",
    value: "SpotOn Retail POS",
  },
  {
    name: "Ecommerce/online retail",
    value: "Ecommerce/online retail",
  },
  {
    name: "Website creation",
    value: "Website creation",
  },
  {
    name: "Other",
    value: "Other",
  },
];

export const usStates = [
  { name: "ALABAMA", abbreviation: "AL" },
  { name: "ALASKA", abbreviation: "AK" },
  { name: "ARIZONA", abbreviation: "AZ" },
  { name: "ARKANSAS", abbreviation: "AR" },
  { name: "CALIFORNIA", abbreviation: "CA" },
  { name: "COLORADO", abbreviation: "CO" },
  { name: "CONNECTICUT", abbreviation: "CT" },
  { name: "DELAWARE", abbreviation: "DE" },
  { name: "DISTRICT OF COLUMBIA", abbreviation: "DC" },
  { name: "FLORIDA", abbreviation: "FL" },
  { name: "GEORGIA", abbreviation: "GA" },
  { name: "HAWAII", abbreviation: "HI" },
  { name: "IDAHO", abbreviation: "ID" },
  { name: "ILLINOIS", abbreviation: "IL" },
  { name: "INDIANA", abbreviation: "IN" },
  { name: "IOWA", abbreviation: "IA" },
  { name: "KANSAS", abbreviation: "KS" },
  { name: "KENTUCKY", abbreviation: "KY" },
  { name: "LOUISIANA", abbreviation: "LA" },
  { name: "MAINE", abbreviation: "ME" },
  { name: "MARYLAND", abbreviation: "MD" },
  { name: "MASSACHUSETTS", abbreviation: "MA" },
  { name: "MICHIGAN", abbreviation: "MI" },
  { name: "MINNESOTA", abbreviation: "MN" },
  { name: "MISSISSIPPI", abbreviation: "MS" },
  { name: "MISSOURI", abbreviation: "MO" },
  { name: "MONTANA", abbreviation: "MT" },
  { name: "NEBRASKA", abbreviation: "NE" },
  { name: "NEVADA", abbreviation: "NV" },
  { name: "NEW HAMPSHIRE", abbreviation: "NH" },
  { name: "NEW JERSEY", abbreviation: "NJ" },
  { name: "NEW MEXICO", abbreviation: "NM" },
  { name: "NEW YORK", abbreviation: "NY" },
  { name: "NORTH CAROLINA", abbreviation: "NC" },
  { name: "NORTH DAKOTA", abbreviation: "ND" },
  { name: "OHIO", abbreviation: "OH" },
  { name: "OKLAHOMA", abbreviation: "OK" },
  { name: "OREGON", abbreviation: "OR" },
  { name: "PENNSYLVANIA", abbreviation: "PA" },
  { name: "RHODE ISLAND", abbreviation: "RI" },
  { name: "SOUTH CAROLINA", abbreviation: "SC" },
  { name: "SOUTH DAKOTA", abbreviation: "SD" },
  { name: "TENNESSEE", abbreviation: "TN" },
  { name: "TEXAS", abbreviation: "TX" },
  { name: "UTAH", abbreviation: "UT" },
  { name: "VERMONT", abbreviation: "VT" },
  { name: "VIRGINIA", abbreviation: "VA" },
  { name: "WASHINGTON", abbreviation: "WA" },
  { name: "WEST VIRGINIA", abbreviation: "WV" },
  { name: "WISCONSIN", abbreviation: "WI" },
  { name: "WYOMING", abbreviation: "WY" },
];
